import React from "react";
import Hero from "./Night4Inno/Hero";
import Header from "./Header_Nav/HeaderTopTwoColors";
import FooterSimple from "./Footer/FooterSimple";
import Startups from "./Night4Inno/Startups";



const Night4Inno = () => {

    return (
        <div>
            <Header />
            <Hero />
            <Startups />
            <FooterSimple />
        </div>
    )
}

export default Night4Inno;